<template>
  <div>
    <div class="d-flex flex-column flex-root loginPage">
      <div
        id="kt_login"
        :class="{
          'login-signin-on': this.state == 'signin',
          'login-forgot-on': this.state == 'forgot',
          'login-forgotnewpassword-on': this.state == 'forgotnewpassword',
        }"
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      >
        <!--begin::Aside-->
        <div
          class="login-aside d-flex flex-column flex-row-auto"
          style="background-color: #e07a36"
        >
          <!-- #f2c98a -->
          <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <a class="text-center mb-10" href="#">
              <!-- <img alt="" class="max-h-70px" src="media/logos/t9_logo.webp" /> -->
            </a>
            <h3
              class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
              style="color: #986923"
            >
              <!-- Discover Amazing Genic Teams <br />with great build tools -->
            </h3>
          </div>
          <div
            :style="{ backgroundImage: `url(${backgroundImage})` }"
            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          ></div>
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
        >
          <div class="d-flex flex-column-fluid flex-center">
            <v-app class="login-page">
              <!--begin::Signin-->
              <div class="login-form login-signin">
                <v-form
                  id="kt_login_signin_form"
                  ref="loginForm"
                  v-model.trim="formValid"
                  class="form"
                  lazy-validation
                >
                  <div
                    class="alert fade alert-danger"
                    role="alert"
                    v-bind:class="{ show: errors.length }"
                  >
                    <div
                      v-for="(error, i) in errors"
                      :key="i"
                      class="alert-text"
                    >
                      {{ error }}
                    </div>
                  </div>
                  <div class="pb-13 pt-lg-0 pt-5">
                    <div style="text-align: center; margin-bottom: 20px">
                      <img
                        alt=""
                        class="max-h-70px"
                        :src="logo"
                        style="width: 40%"
                      />
                    </div>

                    <h3
                      class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                    >
                      Welcome to Genic Teams
                    </h3>
                  </div>
                  <div class="form-group">
                    <label class="font-size-h6 font-weight-bolder text-dark"
                      >Email</label
                    >
                    <div
                      id="email-input-group"
                      label=""
                      label-for="email-input"
                    >
                      <v-text-field
                        v-model.trim="form.email"
                        :disabled="isDisabled"
                        :rules="[
                          validateRules.required(form.email, 'Email'),
                          validateRules.validEmail(form.email, 'Email'),
                        ]"
                        autocomplete="off"
                        @keydown.enter="onSubmitLogin"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="d-flex justify-content-between mt-n5">
                      <label
                        class="font-size-h6 font-weight-bolder text-dark pt-5"
                        >Password</label
                      >
                      <a
                        id="kt_login_forgot"
                        class="text-primary link font-size-h6 font-weight-bolder text-hover-primary pt-5"
                        link
                        @click="showForm('forgot')"
                        >Forgot Password ?</a
                      >
                    </div>
                    <div
                      id="password-input-group"
                      label=""
                      label-for="password-input"
                    >
                      <v-text-field
                        v-model.trim="form.password"
                        :disabled="isDisabled"
                        :rules="[
                          validateRules.required(form.password, 'Password'),
                        ]"
                        autocomplete="off"
                        type="password"
                        @keydown.enter="onSubmitLogin"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="pb-lg-0 pb-5">
                    <button
                      type="button"
                      ref="kt_login_signin_submit"
                      :disabled="isDisabled || !formValid"
                      class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                      v-on:click="onSubmitLogin"
                    >
                      Sign In
                    </button>
                  </div>
                </v-form>
              </div>
              <!--end::Signin-->
              <!--begin::Forgot-->
              <div class="login-form login-forgot">
                <!--begin::Form-->
                <form
                  id="kt_login_forgot_form"
                  ref="kt_login_forgot_form"
                  class="form"
                  novalidate="novalidate"
                >
                  <div
                    class="alert fade alert-danger"
                    role="alert"
                    v-bind:class="{ show: errors.length }"
                  >
                    <div
                      v-for="(error, i) in errors"
                      :key="i"
                      class="alert-text"
                    >
                      {{ error }}
                    </div>
                  </div>
                  <div class="pb-13 pt-lg-0 pt-5">
                    <h3
                      class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                    >
                      Forgotten Password ?
                    </h3>
                    <p class="text-muted font-weight-bold font-size-h4">
                      Enter your email to reset your password
                    </p>
                  </div>
                  <div class="form-group">
                    <v-text-field v-model="form.email"></v-text-field>
                    <!-- <input
                    autocomplete="off"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    name="form.email"
                    placeholder="Email"
                    type="email"
                  /> -->
                  </div>

                  <div
                    v-if="rentmessage"
                    class="alert alert-success"
                    role="alert"
                  >
                    <div class="alert-text">
                      {{ rentmessage }}
                    </div>
                  </div>

                  <div class="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      id="kt_login_forgot_submit"
                      class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      type="button"
                      v-on:click="forgotpassword('forgotnewpassword')"
                    >
                      Submit
                    </button>
                    <button
                      id="kt_login_forgot_cancel"
                      class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      type="button"
                      @click="showForm('signin')"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
              <!--end::Forgot-->

              <!--begin::ForgotResetPasssword-->
              <div class="login-form login-forgotnewpassword">
                <!--begin::Form-->
                <form
                  id="kt_login_forgotnewpassword_form"
                  ref="kt_login_forgotnewpassword_form"
                  class="form"
                  novalidate="novalidate"
                >
                  <div
                    class="alert fade alert-danger"
                    role="alert"
                    v-bind:class="{ show: errors.length }"
                  >
                    <div
                      v-for="(error, i) in errors"
                      :key="i"
                      class="alert-text"
                    >
                      {{ error }}
                    </div>
                  </div>

                  <div
                    v-if="rentmessage"
                    class="alert alert-success"
                    role="alert"
                  >
                    <div class="alert-text">
                      {{ rentmessage }}
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="font-size-h6 font-weight-bolder text-dark"
                      >New Pasword</label
                    >
                    <div
                      id="email-input-group"
                      label=""
                      label-for="email-input"
                    >
                      <v-text-field
                        v-model="form.password"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        v-on:click:append="show2 = !show2"
                        placeholder="New Password"
                      ></v-text-field>
                    </div>
                    <label class="font-size-h6 font-weight-bolder text-dark"
                      >Conform Pasword</label
                    >
                    <div
                      id="email-input-group"
                      label=""
                      label-for="email-input"
                    >
                      <v-text-field
                        v-model="form.conform_password"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show3 ? 'text' : 'password'"
                        v-on:click:append="show3 = !show3"
                        placeholder="Confirm Password"
                      ></v-text-field>
                    </div>
                    <label class="font-size-h6 font-weight-bolder text-dark"
                      >OTP</label
                    >
                    <div
                      id="email-input-group"
                      label=""
                      label-for="email-input"
                    >
                      <v-text-field v-model="form.password_otp"></v-text-field>
                    </div>
                  </div>

                  <div class="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      id="kt_login_forgot_submit"
                      class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      type="button"
                      v-on:click="ConformPassword"
                    >
                      Submit
                    </button>
                    <button
                      id="kt_login_forgot_cancel"
                      class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      type="button"
                      @click="showForm('signin')"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
              <!--begin::ForgotResetPasssword-->
            </v-app>
          </div>
          <!--begin::Content footer-->
          <!--  <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a class="text-primary font-weight-bolder font-size-h5" href="#"
            >Terms</a
          >
          <a class="text-primary ml-10 font-weight-bolder font-size-h5" href="#"
            >Plans</a
          >
          <a class="text-primary ml-10 font-weight-bolder font-size-h5" href="#"
            >Contact Us</a
          >
        </div> -->
          <!--end::Content footer-->
        </div>
        <div
          style="
            bottom: 20px;
            position: absolute;
            right: 20px;
            font-weight: 600;
          "
        >
          Powered by Genic Teams
        </div>
        <!--end::Content-->
      </div>
    </div>
    <NoConnectionDialog
      v-if="false"
      :connection-dialog="isOffline"
    ></NoConnectionDialog>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login.scss";
</style>

<script>
import NoConnectionDialog from "@/view/components/NoConnectionDialog.vue";

import { mapState } from "vuex";
import KTUtil from "@/assets/js/components/util";
import validationMixin from "@/core/plugins/validation-mixin";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import { PUT } from "@/core/services/store/request.module";
import AppConfiguration from "@/core/config/app.config";
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
//import { mapGetters } from "vuex";
export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      logo: null,
      show2: false,
      show3: false,
      ErrorMsg: "",
      state: "signin",
      isDisabled: false,
      isOffline: !navigator.onLine,
      rentmessage: "",
      form: {
        email:
          process.env.NODE_ENV === "development" ? "admin@bthrust.com" : null,
        password: process.env.NODE_ENV === "development" ? "admin@123" : null,
        password_otp: null,
        conform_password: null,
      },
    };
  },
  components: {
    NoConnectionDialog,
  },
  methods: {
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine; // Update flag based on connection status
    },
    ConformPassword() {
      const _this = this;

      _this.pageLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "confirm-password",
          data: {
            password_otp: _this.form.password_otp,
            password: _this.form.password,
            conform_password: _this.form.conform_password,
          },
        })
        .then((response) => {
          console.log(response.message, "password");
          _this.rentmessage = response.message;
          _this.showForm("signin");
        })
        .catch((error) => {
          // console.log({error:error});
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    forgotpassword(form) {
      const _this = this;
      _this.pageLoading = true;
      /*  if(){

      } */

      _this.$store
        .dispatch(PUT, {
          url: "forgotnew-password",
          data: {
            user_email: _this.form.email,
          },
        })
        .then((response) => {
          // console.log(response.message, "email");
          _this.rentmessage = response.message;
          if (form) {
            this.state = form;
            var form_name = "kt_login_" + form + "_form";
            KTUtil.animateClass(
              KTUtil.getById(form_name),
              "animate__animated animate__backInUp"
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    onSubmitLogin() {
      const _this = this;
      if (!_this.$refs.loginForm.validate()) {
        return false;
      }

      _this.formErrors = [];

      const user_email = _this.form.email;
      const password = _this.form.password;

      // clear existing errors
      _this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = _this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      _this.isDisabled = true;
      _this.$store
        .dispatch(LOGIN, { user_email, password })
        .then(() => {
          const resolved = _this.$router.resolve({ name: "admin.dashboard" });
          window.location.href = resolved.href;
        })
        .catch((error) => {
          _this.logError(error);
          _this.isDisabled = false;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  mounted() {
    // console.log(this.localDB());
    let Config = AppConfiguration.get();
    this.logo = Config ? Config.Logo : null;

    this.$store.dispatch(LOGOUT);
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
      message: (state) => state.auth.message,
    }),
    backgroundImage() {
      return (
        process.env.VUE_APP_BASE_URL +
        "media/svg/illustrations/login-visual-1.svg"
      );
    },
    // ...mapGetters([ "localDB"]),
  },
  created() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    // Clean up event listeners to avoid memory leaks
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
